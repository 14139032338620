// extracted by mini-css-extract-plugin
export var adBox = "_2023-hokkus-module--adBox--e020e";
export var adImageBox = "_2023-hokkus-module--adImageBox--9a2a7";
export var adsList = "_2023-hokkus-module--adsList--d4ae8";
export var background2023 = "_2023-hokkus-module--background2023--0de99";
export var columnInner = "_2023-hokkus-module--columnInner--4f78d";
export var contentBox2023 = "_2023-hokkus-module--contentBox2023--df23a";
export var contentPadding = "_2023-hokkus-module--contentPadding--2b66e";
export var contentStyle2023 = "_2023-hokkus-module--contentStyle2023--9dd45";
export var hoverable2023 = "_2023-hokkus-module--hoverable2023--755e0";
export var logo2023 = "_2023-hokkus-module--logo2023--b6282";
export var root2023 = "_2023-hokkus-module--root2023--12721";
export var showsColumn2023 = "_2023-hokkus-module--showsColumn2023--e45e9";
export var showsStyle2023 = "_2023-hokkus-module--showsStyle2023--eee44";
export var titleImg = "_2023-hokkus-module--titleImg--bbaf3";
export var toggleBox = "_2023-hokkus-module--toggleBox--fd2b2";
export var toggleBoxGrid = "_2023-hokkus-module--toggleBoxGrid--139ab";
export var toggleTitle = "_2023-hokkus-module--toggleTitle--b682f";