import React, { useState } from 'react';

import ArrowIcon from '../assets/inline/open_menu.svg';

const ToggleBox = ({
  title,
  titleClassName,
  defaultOpen,
  children,
  ...other
}) => {
  const initialOpen = defaultOpen || false;
  const [open, setOpen] = useState(initialOpen);
  const buttonStyles = {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    border: 'none',
    background: 'transparent',
  };

  return (
    <div {...other}>
      <button
        className={titleClassName}
        style={buttonStyles}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <ArrowIcon
          style={{
            maxHeight: '1rem',
            height: '1rem',
            marginRight: '0.5rem',
            transform: open ? 'none' : 'rotate(-90deg)',
          }}
        />
        {title}
      </button>
      <div style={{ display: open ? 'block' : 'none' }}>{children}</div>
    </div>
  );
};

export default ToggleBox;
