import React from 'react';
import { Helmet } from 'react-helmet';

import DefaultLayout from '../layouts';
import Metadata from '../components/metadata';
import ToggleBox from '../components/toggle-box';

import * as styles from './2023-hokkus.module.css';

import logo_poas from '../assets/common/ads/poas.svg';
// import logo_tek from '../assets/common/ads/tek-2023-valkoinen.svg';
import logo_tek_slogan from '../assets/common/ads/tek-2023-valkoinen-slogan.svg';
import logo_solita from '../assets/common/ads/solita.png';
import logo_atostek from '../assets/common/ads/atostek.png';

import titleImg from '../assets/2023/logo.png';

// --- all required data definitions below ---

const sponsors = [
  { href: 'https://www.atostek.com/', src: logo_atostek, title: 'Atostek' },
  { href: 'https://www.poas.fi/', src: logo_poas, title: 'POAS' },
  { href: 'https://www.solita.fi/', src: logo_solita, title: 'Solita' },
  { href: 'https://www.tek.fi', src: logo_tek_slogan, title: 'TEK' },
];

const crew = [
  {
    title: 'Bändi',
    members: [
      <strong>Juho Viitala - rummut</strong>,
      <strong>Sini Manninen - viulu</strong>,
      'Aleksi Tuunainen - kitara',
      'Anette Karppinen - alttoviulu, trumpetti',
      'Eedit Taipale - laulu',
      'Joonas Nevavuori - saksofoni',
      'Milla Tyyskä - koskettimet',
      'Roope Suojanen - basso',
      'Sanni Koskela - konserttikantele',
    ],
  },
  {
    title: 'Hallitus',
    members: [
      'Anna Kuisma',
      'Daniel Lopez',
      'Emma Brown',
      'Emma Taalikka',
      'Erik Erola',
      'Henri Kiiski',
      'Janita Rintala',
      'Jenni Arponen',
      'Lauri Pitkäjärvi',
      'Maisa Virtanen',
      'Miika Kotajärvi',
      'Miikka Saraniemi',
      'Saga Porjo',
      'Sara Nortunen',
      'Suvi Huotari',
      'Taneli Hongisto',
      'Veera Lignell',
    ],
  },
  {
    title: 'Hyvinvointi & viihtyvyys',
    members: [
      <strong>Helena Ukkola</strong>,
      'Amina Kararic',
      'Emilia Uusi-Heikkilä',
      'Jemina Raukamo',
      'Maria Kuusisto',
      'Marie Manninen',
      'Riina Kangas',
      'Sara Nortunen',
      'Tytti Hyvönen',
      'Veera Hahtimaa',
    ],
  },
  {
    title: 'Käsikirjoitus',
    members: [
      <strong>Peitsa Leinonen</strong>,
      <strong>Sanna Kallio</strong>,
      'Antti Niiranen',
      'Ea Hautala',
      'Harri Kailanto',
      'Juho Jokelainen',
      'Miikka Saraniemi',
      'Niina Palmu',
      'Riina Lahtinen',
      'Sara Nortunen',
      'Sauli Lasanen',
      'Tytti Hyvönen',
    ],
  },
  {
    title: 'Laulunopettaja',
    members: ['Jenni Arponen'],
  },
  {
    title: 'Lavastus & näyttämömestari',
    members: [
      <strong>Daniel López Mängel</strong>,
      <strong>Sakari Huuskola</strong>,
      'Aada Härmä',
      'Ari Alanko',
      'Alfred Altsten',
      'Annoora Karppinen',
      'Anniina Tömäjärvi',
      'Antti Kemppi',
      'Antti Niiranen',
      'Henna Herronen',
      'Henri Kiiski',
      'Inka Inovaara',
      'Jemina Raukamo',
      'Jetro Järvelä',
      'Juho Jokelainen',
      'Juho Mäntysaari',
      'Karoliina Hopia',
      'Lauri Pitkäjärvi',
      'Maria Arro',
      'Maria Kuusisto',
      'Matti Kujala',
      'Matti Pekkanen',
      'Miika Kotajärvi',
      'Niko Mikkonen',
      'Silja Mäkinen',
      'Susanna Rouru',
      'Taika Kuivalainen',
      'Taneli Hongisto',
      'Ulrika Kaara',
      'Veera Hahtimaa',
      'Vilina Wikström',
    ],
  },
  {
    title: 'Lipunmyynti',
    members: [<strong>Henri Kiiski</strong>,
    'Amina Kararic',
    'Jemina Raukamo',
    'Maria Kuusisto',
    'Santtu Laine',
    'Tytti Hyvönen',
    'Veera Hahtimaa',
    ],
  },
  {
    title: 'Logistiikka',
    members: [
      <strong>Lauri Pitkäjärvi</strong>,
      'Antti Niiranen',
      'Ari Alanko',
      'Emmi Oikkonen',
      'James Afolaranmi',
      'Katja Kujala',
      'Matti Pekkanen',
      'Sampo Syrjänen',
    ],
  },
  {
    title: 'Mainosmyynti',
    members: [
      <strong>Jenni Arponen</strong>,
      'Henri Kiiski',
      'Janita Rintala',
      'Meiju Koivuniemi',
    ],
  },
  {
    title: 'Markkinointi',
    members: [
      <strong>Inari Isohanni</strong>,
      'Ella Puranen',
      'Emma Salli',
      'Roosa Jarkko',
      'Säde Lappalainen',
      'Veera Känsälä',
      'Veera Niemelä',
      'Emilia Hautamäki',
      'Inga Ryhänen',
      'Iida Jalkanen',
      'Mari Juvani',
    ],
  },
  {
    title: 'Näyttelijät',
    members: [
      'Emma Taalikka - JC',
      'Jenni Nerg - Opettaja',
      'Kaisa Kuvaja - Lee',
      'Kamilla Mäntynen - Biss',
      'Lauri Ahonen - Dabra',
      'Mesi Pollari - Velho-opettaja, Pelihallin omistaja, Uutistenlukija',
      'Roope Salonen - Gorgobaba, rehtori',
      'Satu Tammekann - Roxxi',
      'Teemu Nieminen - Labim',
    ],
  },
  {
    title: 'Ohjaajat',
    members: ['Harri Kailanto, ohjaaja', 'Leena Jarkko, ohjaajan assistentti'],
  },
  {
    title: 'Puvustus',
    members: [
      <strong>Erika Kaski</strong>,
      <strong>Riina Lahtinen</strong>,
      'Emma Taalikka',
      'Emmi Oikkonen',
      'Enni Uitamo',
      'Hanna San Martín',
      'Heidi Hansen-Haug',
      'Henna Salosyrjä',
      'Inka Inovaara',
      'Jetro Järvelä',
      'Juho Jokelainen',
      'Laura Kastell',
      'Maisa Virtanen',
      'Matilda Hiukka',
      'Matti Kujala',
      'Miika Kotajärvi',
      'Minerva Orenius',
      'Niina Palmu',
      'Sanna Kallio',
      'Sara Lemieux',
      'Siina Siuvo',
    ],
  },
  {
    title: 'Ruokatiimi',
    members: [
      <strong>Antti Niiranen</strong>,
      'Ari Alanko',
      'Erika Kaski',
      'Juho Mäntysaari',
      'Katja Kujala',
      'Maria Kuusisto',
      'Marie Manninen',
      'Matti Kujala',
      'Matti Pekkanen',
      'Miika Kotajärvi',
      'Riina Lahtinen ',
      'Rolando San Martín',
      'Timo Miinalainen',
    ],
  },
  {
    title: 'Sanoitus',
    members: [
      <strong>Pyry Qvick</strong>,
      'Anni Kallunki',
      'Annoora Karppinen',
      'Heidimaria Systä',
      'Jenni Arponen',
      'Jessica Koskiranta',
      'Miikka Saraniemi',
      'Sanna Kallio',
    ],
  },
  {
    title: 'Taikatiimi (hiukset & maski)',
    members: [
      <strong>Minna Rajala</strong>,
      'Annika Aro',
      'Emma Brown',
      'Emmi Oikkonen',
      'Emmi Tallila',
      'Emmi Valassaari',
      'Eveliina Kankaanpää',
      'Hanna San Martín',
      'Henna Herronen',
      'Henna Salosyrjä',
      'Sanna Kallio',
      'Sara Nortunen',
      'Taika Tikkanen',
      'Milla mäenhovi',
      'Shiona',
    ],
  },
  {
    title: 'Taltiointi',
    members: [
      <strong>Eetu Airaksinen</strong>,
      'Aino Jokela',
      'Aleksi Eilo',
      'Alfred Altsten',
      'Anni Kallunki',
      'Antti Kallunki',
      'Anette Karppinen',
      'Emma Toikkanen',
      'Jenni Määttä',
      'Joonas Laine',
      'Juha-Matti Hakojärvi',
      'Juho Nuottimäki',
      'Juho Pyykkönen',
      'Lauri Niittymäki',
      'Lumo Sahlberg',
      'Maria Arro',
      'Miikka Saraniemi',
      'Mira Kokko',
      'Mohammad Asif Ibtehaz',
      'Niklas Onnela',
      'Olivia Keskinen',
      'Rene Lukka',
      'Siina Siuvo',
      'Vivan Kosunen',
    ],
  },
  {
    title: 'Tanssijat & koreografit',
    members: [
      <strong>Anni Hakala</strong>,
      <strong>Olivia Myntti</strong>,
      'Anni Hakala',
      'Olivia Myntti',
      'Adelia Aalto',
      'Anni Lehtimäki',
      'Ella Riikonen',
      'Jenni Torvinen',
      'Marja Seppälä',
      'Meiju Koivuniemi',
      'Suvi Halonen',
      'Veera Elo',
      '',
      'Lisa Salo',
      'Marianne Lehtinen',
      'Rudolf Võrk',
      'Iida Heino',
    ],
  },
  {
    title: 'Tarpeisto',
    members: [
      <strong>Ulrika Kaara</strong>,
      'Aada Härmä',
      'Anu Juvonen',
      'Antti Niiranen',
      'Ari Alanko',
      'Emmi Tallila',
      'Emilia Uusi-Heikkilä',
      'Esa Lakaniemi',
      'Hanna San Martín',
      'Henri Manninen',
      'Inka Inovaara',
      'Juho Jokelainen',
      'Kaisa Kjellman',
      'Lasse Enäsuo',
      'Lauri Kuusisto',
      'Lauri Pitkäjärvi',
      'Maria Arro',
      'Marie Manninen',
      'Matti Kujala',
      'Niina Palmu',
      'Niko Mikkonen',
      'Peitsa Leinonen',
      'Rolando San Martín',
      'Sanna Kallio',
      'Susanna Rouru',
      'Veera Hahtimaa',
      'Vilina Wikström',
    ],
  },
  {
    title: 'Tekniikka',
    members: [
      <strong>Niko Gullsten</strong>,
      <strong>Rene Lukka</strong>,
      'Erik Erola',
      'Jaakko Kronqvist',
      'Joonas Laine',
      'Juho Jokelainen',
      'Juho Nuottimäki',
      'Lauri Pitkäjärvi',
      'Lumo Sahlberg',
      'Mikko Kohonen',
      'Sampo Syrjänen',
      'Sami Hartala',
      'Tuomas Erkkilä',
    ],
  },
  {
    title: 'Tuottajat',
    members: ['Emma Brown', 'Erik Erola', 'Jenni Arponen', 'Suvi Huotari'],
  },
  {
    title: 'Velhot',
    members: [
      <strong>Matti Kujala</strong>,
      'Henri Kiiski',
      'Jaakko Kronqvist',
      'Juho Jokelainen',
      'Eetu Airaksinen',
      'Sami Hartala',
      'Katja Kujala',
      'Ulrika Kaara',
    ],
  },
  {
    title: 'Visualistit',
    members: [
      <strong>Emma Taalikka</strong>,
      'Amina Kararic',
      'Anu Juvonen',
      'Emmi Tallila',
      'Henna Herronen',
      'Janna Urhonen',
      'Kamilla Mäntynen',
      'Sanna Kallio',
      'Siina Siuvo',
      'Sisu Suominen',
      'Utu Metsälä',
    ],
  },
  {
    title: 'WWW',
    members: [<strong>Taneli Hongisto</strong>, 'Kaisa Silkelä'],
  },
  {
    title: 'Äänenkäytönopettaja',
    members: ['Miikka Saraniemi'],
  },
];

const shows = [
  {
    city: 'Helsinki',
    building: 'Aleksanterin teatteri',
    date: 'Ma 13.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/hokkus/',
  },
  {
    city: 'Tampere I',
    building: 'Tampere-talo',
    date: 'Pe 17.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/hokkus/',
  },
  {
    city: 'Tampere II',
    building: 'Tampere-talo',
    date: 'La 18.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/hokkus/',
  },
  {
    city: 'Tampere III',
    building: 'Pakkahuone',
    date: 'Ma 20.11.',
    time: '18',
    link:
      'https://www.tiketti.fi/naaspeksi-2023-tullikamarin-pakkahuone-tampere-lippuja/94612',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Tampere IV',
    building: 'Pakkahuone',
    date: 'Ti 21.11.',
    time: '18',
    link:
      'https://www.tiketti.fi/naaspeksi-2023-tullikamarin-pakkahuone-tampere-lippuja/94612',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Tampere V',
    building: 'Pakkahuone',
    date: 'Ke 22.11.',
    time: '18',
    link:
      'https://www.tiketti.fi/naaspeksi-2023-tullikamarin-pakkahuone-tampere-lippuja/94612',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Turku',
    building: 'Logomo',
    date: 'Ke 29.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/hokkus/',
  },
];

// --- all required data definitions above ---

const Raina2023 = ({ location }) => (
  <DefaultLayout>
    {/* Set navigation bar background color here, because module.css is also loaded on other pages */}
    <style
      dangerouslySetInnerHTML={{
        __html: `
          nav {
            background: rgb(207 60 182) !important;
          }

          nav ul {
            border-color: rgb(207 60 182) !important;
          }
          `,
      }}
    />
    <Helmet htmlAttributes={{ class: styles.root2023 }} />
    <Metadata
      page={{
        title: 'Hokkuspokkus, beibi! - Takaisin taikamaailmaan',
        excerpt: 'Rainasivut vuoden 2023 NääsPeksi-produktiolle',
      }}
      location={location}
    />
    <InfoBoard />
  </DefaultLayout>
);

const InfoBoard = () => {
  return (
    <div>
      <div>
        <Header />
        <div className={styles.background2023}>
          <div className={styles.contentStyle2023}>
            <div className={styles.contentBox2023}>
              <SynopsisColumn />
              <ShowsColumn shows={shows} />
              <CrewColumn crew={crew} />
              <SponsorColumn sponsors={sponsors} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.logo2023}>
      <img
        src={titleImg}
        alt="Hokkuspokkus, beibi!"
        title="Hokkuspokkus, beibi! - Takaisin taikamaailmaan"
        className={styles.titleImg}
        height="17rem"
      />
    </div>
  );
};

const SynopsisColumn = () => {
  return (
    <div className={styles.contentPadding}>
      <div className={styles.synopsisStyle2023}>
        <p>
          Velhokaverukset Labim ja Dabra ovat toistensa täydet vastakohdat.
          Labim on synnynnäinen magian mestari ja koulunsa priimus, mutta
          Dabralta eivät luonnistu yksinkertaisimmatkaan aloittelijaloitsut.
          Kerrankin onnistuneelta vaikuttanut portaaliloitsu meneekin pieleen
          odottamattomalla tavalla, ja he löytävät itsensä uudesta maailmasta,
          jossa taikavoimat eivät enää määrittele heitä. Portaali kuitenkin
          nappaa mukaansa myös yhden ylimääräisen matkustajan…
        </p>

        <p>
          Hokkuspokkus, Beibi! juhlistaa yhdessä tekemisen voimaa jo 25:nnen
          kerran NääsPeksin historiassa. Tule siis juhlistamaan teatterimuotoa,
          jota et näe opiskelijatuotantojen ulkopuolella, ja tule huutamaan
          taikasanoista mahtavinta: OMSTART!
        </p>
      </div>
    </div>
  );
};

const showColumnStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

const ShowsColumn = ({ shows }) => {
  return (
    <div className={[styles.contentPadding]}>
      <h1>Esitykset</h1>

      <p>
        Ovet aukeavat klo 17. Esitykset alkavat tasatunnein, ja ne esitetään
        suomeksi. Esityksen arvioitu kesto on noin 3,5h, mutta sen
        interaktiivisesta luonteesta johtuen, yleisön aktiivisuus vaikuttaa sen
        lopulliseen pituuteen. Esitys sisältää väliajan. Salien istumapaikat
        täytetään vapaasti saapumisjärjestyksessä.
      </p>

      <p>
        Kaipaatko lisätietoja? Kysymyksiisi vastaa tuottajat@naaspeksi.fi, ja
        lippuasioissa liput@naaspeksi.fi
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={styles.hoverable2023}>
          <a href="https://kauppa.naaspeksi.fi/naaspeksi/hokkus/">
            <h2>Liput</h2>
            <p style={{ margin: '0' }}>25 € Työssäkäyvät</p>
            <p style={{ margin: '0' }}>30 € Kannatuslippu</p>
            <p style={{ margin: '0 0 1em 0' }}>
              15 € Opiskelijat, eläkeläiset, varushenkilöt, jne.
            </p>
            <p style={{ margin: '0 0 1em 0' }}>
              Narikka (3 €) ei kuulu lipun hintaan.
            </p>
          </a>
        </div>
      </div>

      <div className={styles.showsColumn2023}>
        {shows.map((show, i) => {
          return (
            <Ticket
              city={show.city}
              building={show.building}
              date={show.date}
              time={show.time}
              link={show.link}
              linkTitle={show.linkTitle}
              key={`show-${i}`}
            />
          );
        })}
      </div>
    </div>
  );
};

const Ticket = ({ city, building, date, time, link, linkTitle }) => {
  return (
    <div className={styles.hoverable2023}>
      <a href={link}>
        <h2>{city}</h2>
        <p style={{ margin: '0' }}>
          <strong>
            {date} klo. {time}
          </strong>
        </p>
        <p style={{ margin: '0' }}>
          <strong>{building}</strong>
        </p>
        <p style={{ margin: '0 0 1em 0' }}>
          {(linkTitle ? linkTitle : 'Lippukauppaan') + (link ? ' »' : '')}
        </p>
      </a>
    </div>
  );
};

const CrewSection = ({ title, members }) => (
  <ToggleBox
    title={title}
    defaultOpen={false}
    titleClassName={styles.toggleTitle}
    className={styles.toggleBox}
  >
    <ul>
      {members.map(member => (
        <li id={`${title}-${member}`}>{member}</li>
      ))}
    </ul>
  </ToggleBox>
);

const CrewColumn = ({ crew }) => (
  <div className={styles.contentPadding}>
    <h1>Tekijät</h1>

    <div className={styles.toggleBoxGrid}>
      <div className={styles.columnInner}>
        {crew.slice(0, Math.ceil(crew.length / 3)).map(section => (
          <CrewSection title={section.title} members={section.members} />
        ))}
      </div>

      <div className={styles.columnInner}>
        {crew
          .slice(Math.ceil(crew.length / 3), -Math.floor(crew.length / 3))
          .map(section => (
            <CrewSection title={section.title} members={section.members} />
          ))}
      </div>

      <div className={styles.columnInner}>
        {crew.slice(-Math.floor(crew.length / 3)).map(section => (
          <CrewSection title={section.title} members={section.members} />
        ))}
      </div>
    </div>
  </div>
);

const SponsorColumn = ({ sponsors }) => (
  <div className={styles.synopsisStyle2023}>
    <div className={styles.contentPadding}>
      <h1>Yhteistyössä</h1>

      <div className={styles.adsList}>
        {sponsors.map((sponsor, i) => (
          <Sponsor
            href={sponsor.href}
            src={sponsor.src}
            title={sponsor.title}
            key={`sponsor-${i}`}
          />
        ))}
      </div>
    </div>
  </div>
);
const Sponsor = ({ href, src, title }) => (
  <div className={styles.adBox}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className={styles.adImageBox}>
        <img src={src} alt={title} title={title} />
      </div>
    </a>
  </div>
);

export default Raina2023;
